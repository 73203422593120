<template>
  <main role="main" class="container">
    <section class="jumbotron text-center">
      <div class="container">
        <h1 class="jumbotron-heading">{{ title }}</h1>
        <div class="lead text-muted">{{ description }}</div>
        <div>
          <a ref="dlBtn" class="btn btn-lg dl-btn" :href="url" @click="downloadInit" @contextmenu.prevent>ダウンロード</a><br>
          <small class="text-muted">{{ filesize(size) }}</small>&nbsp;&nbsp;&nbsp;<small class="text-info" v-html="info"></small>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { settings } from '@/variables'
import filesize from 'filesize'

export default {
  name: 'Download',
  data () {
    return {
      url: '#',
      title: '',
      description: '',
      size: 0,
      dl_count: 0,
      maxDL: settings.maxDownload,
      error: true,
      info: '',
      filesize
    }
  },
  methods: {
    downloadInit (e) {
      if (this.dl_count >= this.maxDL || this.error) {
        e.preventDefault();
        return;
      }
      setTimeout(() => {
        /* eslint-disable no-undef */
        this.$log( 'download', { 'event_label': this.$route.params.hashid });
        this.$http.post('url/download/' + this.$route.params.hashid,
          {
            params: {
              guid: this.$guid()
            }
          }).then((response) => {
          if (response) {
            this.dl_count++;
            if (this.dl_count >= this.maxDL) {
              this.url = '#';
            }
            this.info = `ダウンロード回数：${this.dl_count}/${this.maxDL}`
          }
        })
      }, 1000)
    }
  },
  mounted () {
    if (this.$loggedIn() !== true) {
      /* eslint-disable no-undef */
      gtag('set', {'user_id': this.$guid()}); // Set the user ID using signed-in user_id.
    } else {
      gtag('set', {'user_id': this.$ls.get('user').username}); // Set the user ID using signed-in user_id.
    }
    /* eslint-disable no-undef */
    gtag('config', settings.gtag, {'page_path': '/download/' + this.$route.params.hashid});
    this.$http.get('url/download/' + this.$route.params.hashid,
      {
        params: {
          guid: this.$guid()
        }
      }).then((response) => {
      if (response) {
        this.title = response.data.title;
        this.description = response.data.description;
        this.size = response.data.size;
        this.dl_count = response.data.dl_count
        if (response.data.max) {
          this.maxDL = response.data.max;
        }
        if (this.dl_count < this.maxDL) {
          this.url = response.data.url;
        }
        this.$refs.dlBtn.classList.add('btn-success')
        this.error = false;
        this.info = `ダウンロード回数：${this.dl_count}/${this.maxDL}`
      }
    }).catch((error) => {
      this.$refs.dlBtn.classList.add('btn-danger')
      this.$refs.dlBtn.classList.add('disabled')
      if (error.response) {
        let msg = '';
        switch (error.response.status) {
          case 403:
            msg = '作成する数の上限が越えました';
            break;
          case 404:
            msg = '指定した映像が存在しない';
            break;
          case 410:
            msg = '映像リンクの使用期限が切れました';
            break;
          default:
            if (error.response.data && error.response.data.error) {
              msg = error.response.data.error
            } else {
              msg = 'サーバーエラー';
            }
            if (this.$ab) {
              this.$ab.notify(error);
            }
            break;
        }
        this.$snotify.error(msg);
        this.info = msg;
        if (error.response.data && error.response.data.error) {
          this.title = error.response.data.error;
        }
      }
    })
  }
}
</script>

<style scoped>
  main.container {
    padding: 70px 15px 0;
  }

  .jumbotron {
    padding-top: 6rem;
    padding-bottom: 6rem;
    margin-bottom: 0;
    background-color: #fff;
  }
</style>
