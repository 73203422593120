<template>
    <canvas
        ref="canvas"
        width="560"
        height="560"
    />
</template>

<script>
import {results} from "@/variables";

export default {
    name: 'BattedBallScatter',
    props: {
        battedBall: {
            type: Object,
            default: null
        },
        scale: {
            type: Number,
            default: 0.5
        }
    },
    data () {
        const battedBallFeature = {
            1: { id: 1, name: 'groundball', dash: [4, 4] },
            2: { id: 2, name: 'flyball', dash: [15, 5] },
            3: { id: 3, name: 'linearball', dash: [0, 0] }
        }

        return {
            timeOut: 0,
            timeOut2: 0,
            results,
            battedBallFeature,
            ctx: null
        }
    },
    watch: {
        gameData () {
            clearTimeout(this.timeOut)
            this.timeOut = setTimeout(() => {
                this.drawField()
                this.prepareData()
            }, 500)
        },
    },
    mounted () {
        const scale = (this.scale / 2) * window.devicePixelRatio
        this.$refs.canvas.width = this.$refs.canvas.width * scale
        this.$refs.canvas.height = this.$refs.canvas.height * scale
        this.ctx = this.$refs.canvas.getContext('2d')
        this.ctx.scale(scale, scale)
        clearTimeout(this.timeOut)
        clearTimeout(this.timeOut2)
        this.timeOut = setTimeout(() => {
            this.drawField()
            this.prepareData()
        }, 500)
    },
    methods: {
        drawField () {
            this.ctx.strokeStyle = 'black'
            this.ctx.lineWidth = 20
            this.ctx.clearRect(0, 0, 560, 560)

            // フィールド全体の円
            const largeRad = Math.sqrt((208 - 80) ** 2 + (352 - 68) ** 2)
            this.ctx.beginPath()
            this.ctx.arc(208, 352, largeRad, (24 * Math.PI) / 180, (246 * Math.PI) / 180, true)
            this.ctx.stroke()
            this.ctx.closePath()

            // ファールライン
            this.ctx.beginPath()
            this.ctx.moveTo(80, 480)
            this.ctx.lineTo(560, 480)
            this.ctx.moveTo(80, 480)
            this.ctx.lineTo(80, 0)
            this.ctx.stroke()
            this.ctx.closePath()

            // ベースライン
            this.ctx.beginPath()
            this.ctx.strokeStyle = 'black'

            this.ctx.moveTo(240, 480)
            this.ctx.lineTo(240, 320)
            this.ctx.lineTo(80, 320)
            this.ctx.lineTo(80, 480)
            this.ctx.lineTo(240, 480)
            this.ctx.stroke()
            this.ctx.closePath()
        },
        prepareData () {
            if (this.battedBall) {
                if (this.battedBall.x && this.battedBall.y) {
                    const x = this.battedBall.x
                    const y = this.battedBall.y

                    // 結果で色分け
                    const result = 'result-' + this.battedBall.result
                    this.ctx.strokeStyle = result in this.results ? this.results[result].scope_color : ''
                    this.ctx.lineWidth = 20

                    // 打球性質
                    const feature = this.battedBall.feature.toString()

                    // ゴロ、フライ、ライナーで破線の種類を決める
                    const ballFeature = feature in this.battedBallFeature
                        ? this.battedBallFeature[feature].dash
                        : [0, 0]
                    this.ctx.setLineDash(ballFeature)
                    this.ctx.fillStyle = 'white'

                    this.ctx.beginPath()
                    this.ctx.moveTo(80, 480)
                    this.ctx.lineTo(x * 2, y * 2)
                    this.ctx.stroke()
                    this.ctx.closePath()
                    this.ctx.setLineDash([0, 0])
                    this.ctx.beginPath()
                    this.ctx.arc(x * 2, y * 2, 30, 0, 2 * Math.PI)
                    this.ctx.fill()
                    this.ctx.stroke()
                    this.ctx.closePath()
                }
            }
        }
    },
    beforeDestroy () {
        clearTimeout(this.timeOut)
        if (this.ctx) {
            this.ctx.width = 0
            this.ctx.height = 0
            this.ctx = null
        }
    }
}
</script>

<style scoped>
canvas {
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid grey;
    background-color: white;
    margin-right: 0.1rem;
}
</style>
