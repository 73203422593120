<template>
    <!-- Begin page content -->
    <main role="main" class="container-fluid">
        <b-alert variant="danger" v-if="news.system && news.system.length > 0" show dismissible
                 @dismissed="newsDismissed('system',news.system[0].aid)"><strong>{{ news.system[0].title }}</strong>
            <br>{{ news.system[0].body }}
        </b-alert>
        <b-alert variant="warning" v-if="news.group && news.group.length > 0" show dismissible
                 @dismissed="newsDismissed('group',news.group[0].aid)"><strong>{{ news.group[0].title }}</strong>
            <br>{{ news.group[0].body }}
        </b-alert>
        <div class="row" id="toolbox">
            <div class="col">
                <nav class="navbar navbar-expand-lg navbar-light bg-light">
                    <b-button variant="primary" @click.prevent="openUploadModal" v-if="$parent.user.is_manager"><i
                        class="icon ion-md-add"></i> 映像を追加
                    </b-button>
                    <div class="mr-auto"></div>
                    <form class="form-inline" @submit.prevent="search">
                        <!--div class="form-group ml-1 my-2 my-sm-0">
                          <b-form-datepicker
                            id="datepicker"
                            v-model="shot_date"
                            locale="ja"
                            reset-button close-button
                            placeholder="撮影日"
                            calendar-width="260"
                            :date-format-options="{ 'year': 'numeric', 'month': 'long', 'day': 'numeric', 'weekday': 'long' }"></b-form-datepicker>
                        </div-->
                        <div class="form-group ml-1 my-2 my-sm-0">
                            <datepicker v-model="shot_date" :disabledDates="$parent.datePicker.disabledDates"
                                        bootstrap-styling
                                        calendar-button style="width: 230px" clear-button
                                        :calendar-button-icon="$parent.datePicker.icon"
                                        :language="$parent.datePicker.language"
                                        :format="$parent.datePicker.format"></datepicker>
                        </div>
                        <div class="form-group ml-1 my-2 my-sm-0">
                            <input class="form-control" type="search" placeholder="キーワード" v-model="keyword"
                                   aria-label="キーワード" style="width: 15rem;">
                        </div>
                        <div class="form-group ml-1 my-2 my-sm-0">
                            <b-form-select v-model="selectedTeam" :options="teams"></b-form-select>
                        </div>
                        <button class="btn btn-outline-success ml-1 my-2 my-sm-0" type="submit"><i
                            class="icon ion-md-search"></i> 検索
                        </button>
                    </form>
                </nav>
            </div>
        </div>
        <div class="row">
            <div class="col-md-9 col-12">
                <b-table selectable striped hover :items="videos" :fields="fields" :current-page="currentPage"
                         primary-key="vid"
                         :per-page="perPage" bordered ref="table" @row-selected="rowSelected" @refresh="tableRefresh"
                         :sort-by.sync="sortBy" no-local-sorting no-sort-reset
                         :sort-desc.sync="sortDesc"
                         :select-mode.sync="selectmode" show-empty empty-text="結果ありません">
                    <template v-slot:cell(name)="data">
                        <img :src="data.item.thumb?data.item.thumb:inProgress" alt=""
                             class="img-thumbnail thumb float-left mr-3">
                        <div>{{ data.value }}</div>
                        <small class="text-muted">{{ data.item.description }}</small>
                        <span v-if="$parent.user.is_manager" class="float-right">
                            <b-badge pill variant="light" v-if="data.item.data">タグ付き</b-badge>
                            <b-badge pill variant="secondary" v-if="data.item.archived">アーカイブ</b-badge>
                            <b-badge pill variant="warning" v-if="!data.item.archived && data.item.shared">公開中</b-badge>
                            <b-badge pill variant="secondary"
                                v-if="data.item.status==='init' && getUpload(data.item.video_id) && !getUpload(data.item.video_id).hash">映像ファイルを確認中.. {{ getUpload(data.item.video_id).process }}%</b-badge>
                            <b-badge pill :variant="$parent.statusVariant[data.item.status]"
                                v-if="!data.item.archived">{{ $parent.status[data.item.status] }}<span
                                v-if="data.item.status==='up' && getUpload(data.item.video_id)"> {{ getUpload(data.item.video_id).progress }}%</span></b-badge>
                        </span>
                        <span v-else class="float-right">
                            <b-badge pill variant="light" v-if="data.item.data">タグ付き</b-badge>
                        </span>
                    </template>
                    <template v-slot:cell(created)="data">
                        <div>{{ $moment(data.item.created).local().calendar() }}</div>
                    </template>
                </b-table>
                <b-row>
                    <b-col md="6" class="my-1">
                        <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0"
                                      @change="changePage"/>
                    </b-col>
                    <b-col md="6" class="my-1 text-right">
                        <span>{{ currentRows }} / {{ totalRows }}</span>
                    </b-col>
                </b-row>
            </div>
            <div class="col-md-3 d-none d-md-block">
                <div id="info-box">
                    <div class="card info-card" id="info-card-multi" v-if="multiselect">
                        <div class="card-body">
                            {{ selectedItems.length }} 件選択された
                            <b-table-lite :items="selectedItems" small striped :fields="fields2">
                                <template v-slot:cell(name)="data">
                                    <img :src="data.item.thumb?data.item.thumb:inProgress" alt=""
                                         class="rounded thumb-sm float-left mr-3">
                                    <div class="small">{{ data.value }}</div>
                                </template>
                            </b-table-lite>
                        </div>
                        <b-button-group class="no-round">
                            <b-button v-if="!$env().VUE_APP_NOSHARE" variant="warning" @click="editAcl" title="権限"><i class="icon ion-md-key"></i>
                            </b-button>
                            <b-button variant="danger" @click="deleteVideos" title="削除"><i
                                class="icon ion-md-trash"></i></b-button>
                            <b-button variant="secondary" @click="archiveVideos" title="アーカイブ"><i
                                class="icon ion-md-archive"></i></b-button>
                        </b-button-group>
                    </div>
                    <div class="card info-card" id="info-card" v-if="!multiselect">
                        <div class="card-img-top thumbLarge" v-if="selectedItem && selectedItem.status === 'ready'"
                             @click.prevent="playVideo"
                             :style="{ cursor: 'pointer', 'backgroundImage': 'url(' + (selectedItem.poster?selectedItem.poster:inProgress) + ')' }">
                            <img src="@/assets/img/play-button.png" alt="再生" class="playbutton"
                                 v-if="!selectedItem.archived">
                        </div>
                        <div class="card-img-top thumbLarge"
                             v-if="selectedItem && selectedItem.status !== 'ready' && !selectedItem.archived"
                             :style="{backgroundImage: 'url('+inProgress+')'}">
                        </div>
                        <div class="card-body" v-if="selectedItem">
                            <h5 class="card-title" v-html="selectedItem.name"></h5>
                            <div class="card-text mb-3 small">
                                <div>
                                    <span class="font-weight-bold">詳細：</span><span class="text-muted"
                                                                                   v-html="selectedItem.description"></span>
                                </div>
                                <div>
                                    <span class="font-weight-bold">日付：</span><span class="text-muted"
                                                                                   v-html="selectedItem.shot_date"></span>
                                </div>
                                <div v-if="!$env().VUE_APP_NOSHARE">
                                    <span class="font-weight-bold">チーム：</span><span class="text-muted"
                                                                                    v-html="selectedItem.group"></span>
                                </div>
                                <div v-if="!$env().VUE_APP_NOSHARE">
                                    <span class="font-weight-bold">公開チーム数：</span><span class="text-muted"
                                                                                       v-html="selectedItem.sharedCnt"></span>
                                </div>
                            </div>
                        </div>
                        <b-button-group class="no-round"
                                        v-if="$parent.user.is_manager && selectedItem && selectedItem.group_id === $parent.user.group.group_id">
                            <b-button variant="primary" v-if="canRetry" @click="retryVideo" title="再試行"><i
                                class="icon ion-md-refresh"></i></b-button>
                            <b-button variant="warning" @click="editVideo" title="編集"><i class="icon ion-md-create"></i>
                            </b-button>
                            <b-button variant="danger" @click="deleteVideo" title="削除"><i class="icon ion-md-trash"></i>
                            </b-button>
                            <b-button variant="secondary"
                                      v-if="selectedItem.status === 'ready' && !selectedItem.archived"
                                      @click="archiveVideo" title="アーカイブ"><i class="icon ion-md-archive"></i></b-button>
                            <b-button variant="primary" v-if="selectedItem.archived" @click="restore" title="復元"><i
                                class="icon ion-md-refresh"></i></b-button>
                            <b-button variant="info" v-if="selectedItem.status === 'ready' && !selectedItem.archived"
                                      @click="createOneTimeURL" title="DLリンク生成">
                                <i class="icon ion-md-code-download"></i>
                            </b-button>
                        </b-button-group>
                        <b-button-group class="no-round"
                                        v-if="$parent.user.is_manager && selectedItem && selectedItem.group_id !== $parent.user.group.group_id && selectedItem.acl.find(acl=>acl.group_id_id === $parent.user.group.group_id && acl.download)">
                            <b-button variant="info" @click="createOneTimeURL" title="DLリンク生成">
                                <i class="icon ion-md-code-download"></i>
                            </b-button>
                        </b-button-group>
                        <b-button-group class="no-round"
                                        v-if="selectedItem && selectedItem.status === 'ready' && !selectedItem.archived && selectedItem.group_id === $parent.user.group.group_id">
                            <b-button v-if="$parent.user.is_manager" variant="dark" @click="labelVideo" title="タグ編集">
                                <i class="icon ion-md-bookmark"></i>
                            </b-button>
                            <b-button variant="success" @click="searchLabelVideo" title="タグ検索"
                            v-if="selectedItem.data">
                                <i class="icon ion-md-search"></i>
                            </b-button>
                        </b-button-group>
                    </div>
                    <div class="mb-2" v-if="$parent.user.is_manager">
                        <b-form-checkbox v-model="multiselect" name="check-button" switch>
                            複数選択モード（一括権限変更、削除、アーカイブ）
                        </b-form-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import inProgress from '@/assets/img/inprogress.svg'

export default {
    components: {
        Datepicker
    },
    name: 'Main',
    props: {
        groups: Array,
        user: Object
    },
    data() {
        return {
            inProgress,
            shot_date: '',
            keyword: '',
            news: {},
            totalRows: 0,
            currentPage: 1,
            perPage: 20,
            currentRows: '',
            sortBy: 'created',
            sortDesc: true,
            multiselect: false,
            selectmode: 'single',
            fields: [
                {
                    key: 'name',
                    label: '映像',
                    sortable: false
                },
                {
                    key: 'shot_date',
                    sortable: true,
                    label: '撮影日付',
                    thClass: 'date'
                },
                {
                    key: 'created',
                    sortable: true,
                    label: '追加日付',
                    thClass: 'date'
                },
                {
                    key: 'group',
                    sortable: false,
                    label: 'チーム',
                    thClass: 'group'
                }
            ],
            fields2: [
                {
                    key: 'name',
                    label: '映像'
                }
            ],
            selectedItem: null,
            selectedItems: [],
            selectedTeam: null
        }
    },
    watch: {
        multiselect(val) {
            if (val) {
                this.selectmode = 'multi';
            } else {
                this.selectmode = 'single';
                if (this.selectedItem) {
                    this.$refs.table.selectRow()
                }
            }
            this.selectedItems = [];
        }
    },
    methods: {
        tableRefresh() {
            // this.selectedItem = null;
        },
        search() {
            /* eslint-disable no-undef */
            this.$log( 'search', {'search_term': this.keyword});
            this.$refs.table.refresh()
        },
        editAcl() {
            if (this.selectedItems.length) {
                for (let i = 0; i < this.selectedItems.length; i++) {
                    if (this.selectedItems[i].group_id !== this.$parent.user.group.group_id) {
                        this.$snotify.error(`「${this.selectedItems[i].name}」が他チームの映像のため権限をを変更できません。`);
                        return;
                    }
                }
                this.$root.$emit('openAclModal', this.selectedItems);
            }
        },
        editVideo() {
            this.$root.$emit('openVideoEditModal', this.selectedItem.video_id);
        },
        searchLabelVideo() {
            this.$root.$emit('openVideoSearchModal', this.selectedItem);
        },
        labelVideo() {
            if (this.selectedItem.status !== 'ready' || this.selectedItem.archived) {
                return;
            }
            this.$getComponent('App').loading(true)
            this.$http.get('video/'+this.selectedItem.video_id+'?data=true').then((r) => {
                this.$root.$emit('endSpinner');
                if (r && r.data) {
                    this.$parent.getPlayURL(this.selectedItem, (url => {
                        this.$root.$emit('openVideoLabelModal', url, this.selectedItem, r.data.video.data);
                    }))
                }
            }).catch((error) => {
                this.$root.$emit('endSpinner');
                if (error.response) {
                    if (error.response.data && error.response.data.error) {
                        this.$snotify.error(error.response.data.error);
                    } else {
                        this.$snotify.error('サーバーエラー');
                    }
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log(error.config);
                }
                if (this.$ab) {
                    this.$ab.notify(error);
                }
            })
        },
        createOneTimeURL() {
            if (this.selectedItem) {
                this.$parent.createOneTimeURL(this.selectedItem);
            }
        },
        deleteVideo() {
            if (this.selectedItem) {
                this.$parent.deleteVideo(this.selectedItem)
            }
        },
        deleteVideos(event, force) {
            if (this.selectedItems.length) {
                let has = 0;
                for (let i = 0; i < this.selectedItems.length; i++) {
                    if (this.selectedItems[i].group_id !== this.$parent.user.group.group_id) {
                        this.$snotify.error(`「${this.selectedItems[i].name}」が他チームの映像のため削除出来ません。`);
                        return;
                    }
                    let acl = this.selectedItems[i].acl.filter(value => value.streaming).map(value => value.group_id_id).filter(value => value !== this.$parent.user.group.group_id)
                    if (acl.length && !this.selectedItems[i].archived) {
                        has++;
                    }
                }
                if (!force) {
                    if (has > 0) {
                        this.$swal({
                            title: '映像公開中',
                            text: `${has}件の映像が他チームに公開している。そのまま続行しますか？？`,
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#ed9d2b',
                            cancelButtonText: 'キャンセル',
                            confirmButtonText: '続行'
                        }).then((result) => {
                            if (result.value) {
                                this.deleteVideos(null, true);
                            }
                        })
                        return;
                    }
                }

                this.$swal({
                    title: '映像削除',
                    html: `${this.selectedItems.length}件選択された映像を削除しますか？<br>以下の空欄に「delete」を入力して、削除ボタンをクリックしてください`,
                    input: 'text',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#d9534f',
                    cancelButtonText: 'キャンセル',
                    confirmButtonText: '削除',
                    inputValidator: (value) => {
                        if (!value || value !== 'delete') {
                            return '空欄に「delete」を入力してください'
                        }
                    }
                }).then((result) => {
                    if (result.value) {
                        let wait = 0;
                        let uIdxs = [];
                        for (let i = 0; i < this.selectedItems.length; i++) {
                            let uIdx = this.$getComponent('App').uploadGroups.findIndex((v) => v.vid === this.selectedItems[i].video_id)
                            if (uIdx > -1) {
                                uIdxs.push(uIdx);
                                this.$parent.cancelUpload(uIdx, '削除されました');
                                wait = 1000;
                            }
                        }
                        setTimeout(() => {
                            this.$http.post('video/', {
                                oper: 'delete',
                                vids: this.selectedItems.map((a) => a.video_id)
                            }).then(() => {
                                this.$root.$emit('updateTable');
                                this.$snotify.success(this.selectedItems.length + '件の映像が削除されました。'); // TODO
                                if (uIdxs.length) {
                                    setTimeout(() => {
                                        uIdxs.forEach((a) => {
                                            this.$getComponent('App').uploadGroups.splice(a, 1);
                                        })
                                    }, wait)
                                }
                                this.$log( 'delete', {'vid': this.selectedItems.map(a => a.video_id)});
                            }).catch((error) => {
                                if (error.response) {
                                    switch (error.response.status) {
                                        case 404:
                                            this.$snotify.error('指定した映像が存在しない');
                                            break;
                                        case 403:
                                            this.$snotify.error('削除する権限がありません');
                                            break;
                                        default:
                                            if (this.$ab) {
                                                this.$ab.notify(error);
                                            }
                                            if (error.response.data && error.response.data.error) {
                                                this.$snotify.error(error.response.data.error);
                                            } else {
                                                this.$snotify.error('サーバーエラー');
                                            }
                                            break;
                                    }
                                }
                            })
                        }, wait);
                    }
                })
            }
        },
        archiveVideos(event, force) {
            if (this.selectedItems.length) {
                let has = 0;
                for (let i = 0; i < this.selectedItems.length; i++) {
                    if (this.selectedItems[i].group_id !== this.$parent.user.group.group_id) {
                        this.$snotify.error(`「${this.selectedItems[i].name}」が他チームの映像のためアーカイブ出来ません。`);
                        return;
                    }
                    let acl = this.selectedItems[i].acl.filter(value => value.streaming).map(value => value.group_id_id).filter(value => value !== this.$parent.user.group.group_id)
                    if (acl.length && !this.selectedItems[i].archived) {
                        has++;
                    }
                }
                if (!force) {
                    if (has > 0) {
                        this.$swal({
                            title: '映像公開中',
                            text: `${has}件の映像が他チームに公開している。そのまま続行しますか？？`,
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#ed9d2b',
                            cancelButtonText: 'キャンセル',
                            confirmButtonText: '続行'
                        }).then((result) => {
                            if (result.value) {
                                this.archiveVideos(null, true);
                            }
                        })
                        return;
                    }
                }

                this.$swal({
                    title: '映像アーカイブ',
                    html: `${this.selectedItems.length}件選択された映像をアーカイブしますか？<br>以下の空欄に「archive」を入力して、アーカイブボタンをクリックしてください`,
                    input: 'text',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#d9534f',
                    cancelButtonText: 'キャンセル',
                    confirmButtonText: 'アーカイブ',
                    inputValidator: (value) => {
                        if (!value || value !== 'archive') {
                            return '空欄に「archive」を入力してください'
                        }
                    }
                }).then((result) => {
                    if (result.value) {
                        this.$getComponent('App').loading(true)
                        this.$http.post('video/',
                            {
                                oper: 'archive',
                                vids: this.selectedItems.map((a) => a.video_id)
                            }).then((response) => {
                            this.$root.$emit('endSpinner');
                            if (response) {
                                this.$root.$emit('updateTable');
                                this.$snotify.success(this.selectedItems.length + '件の映像がアーカイブされました。');
                                this.$log( 'archive', {'vid': this.selectedItems.map((a) => a.video_id)});
                            }
                        }).catch((error) => {
                            this.$root.$emit('endSpinner');
                            if (error.response) {
                                switch (error.response.status) {
                                    case 404:
                                        this.$snotify.error('指定した映像が存在しない');
                                        break;
                                    case 403:
                                        this.$snotify.error('アーカイブする権限がありません');
                                        break;
                                    default:
                                        if (this.$ab) {
                                            this.$ab.notify(error);
                                        }
                                        if (error.response.data && error.response.data.error) {
                                            this.$snotify.error(error.response.data.error);
                                        } else {
                                            this.$snotify.error('サーバーエラー');
                                        }
                                        break;
                                }
                            }
                        })
                    }
                })
            }
        },
        archiveVideo() {
            if (this.selectedItem) {
                this.$getComponent('Home').archiveVideo(this.selectedItem);
            }
        },
        restore() {
            if (this.selectedItem) {
                this.$getComponent('Home').restoreVideo(this.selectedItem);
            }
        },
        retryVideo() {
            if (this.selectedItem && this.selectedItem.status === 'up_err') {
                let idx = this.$getComponent('App').uploadGroups.findIndex((v) => v.vid === this.selectedItem.video_id)
                if (idx > -1) {
                    this.$getComponent('Home').startUploadGroup(idx)
                }
            }
        },
        playVideo() {
            if (this.selectedItem.archived) {
                return;
            }
            this.$parent.getPlayURL(this.selectedItem, url => {
                if (typeof Android !== 'undefined' && /amazon/i.test(navigator.userAgent)) {
                    // eslint-disable-next-line no-undef
                    Android.openVideo(url.mpd, this.selectedItem.name)
                } else {
                    this.$root.$emit('openVideo', url, this.selectedItem);
                }
            })
        },
        rowSelected(item) {
            console.log('row selected', item)
            if (this.multiselect) {
                this.selectedItems = item;
            } else {
                if (item.length) {
                    this.selectedItem = item[0];
                    /* eslint-disable no-undef */
                    this.$log( 'view_item', {'event_label': this.selectedItem.name});
                } else {
                    // this.selectedItem = null;
                }
            }
        },
        openUploadModal() {
            this.$getComponent('App').loading(true)
            this.$http.get('video/?current=true').then((response) => {
                this.$root.$emit('endSpinner');
                if (response.data.active >= response.data.max_active) {
                    this.$snotify.error('アクティブ映像が上限（' + response.data.max_active + '）を越えました、古い映像をアーカイブしてください')
                    return;
                }
                if (response.data.total >= response.data.max_total) {
                    this.$snotify.error('合計映像が上限（' + response.data.max_total + '）を越えました、古い映像又はいらない映像を削除してください')
                    return;
                }
                this.$root.$emit('openUploadModal');
            }).catch((error) => {
                this.$root.$emit('endSpinner');
                if (error.response) {
                    if (error.response.data && error.response.data.error) {
                        this.$snotify.error(error.response.data.error);
                    } else {
                        if (this.$ab) {
                            this.$ab.notify(error);
                        }
                        this.$snotify.error('サーバーエラー');
                    }
                }
            })
        },
        videos(ctx, cb) {
            let params = '?page=' + ctx.currentPage + '&size=' + ctx.perPage + '&date=' +
                (this.shot_date ? this.$moment(this.shot_date).format('YYYY-MM-DD') : '') + '&keyword=' + this.keyword +
                '&sort=' + this.sortBy + '&desc=' + this.sortDesc + (this.selectedTeam ? '&gid=' + this.selectedTeam : '');
            ctx.busy = true;
            this.$http.get('video/' + params).then((response) => {
                ctx.busy = false;
                this.totalRows = response.data.total;
                cb(response.data.videos);
                let first = 0;
                if (response.data.videos.length > 0) {
                    first = ((ctx.currentPage - 1) * ctx.perPage) + 1;
                    first = first + ' - ' + (first + response.data.videos.length - 1)
                    if (this.selectedItem) {
                        let i = response.data.videos.findIndex(a => a.video_id === this.selectedItem.video_id)
                        if (i > -1) {
                            this.$refs.table.selectRow(i);
                        } else {
                            this.selectedItem = null;
                        }
                    }
                } else {
                    if (this.keyword || this.shot_date) {
                        this.$snotify.warning('結果がありません')
                    }
                    this.selectedItem = null;
                }
                this.currentRows = first
            }).catch((error) => {
                this.selectedItem = null;
                ctx.busy = false;
                if (error.response) {
                    if (error.response.data && error.response.data.error) {
                        this.$snotify.error(error.response.data.error);
                    } else {
                        if (this.$ab) {
                            this.$ab.notify(error);
                        }
                        this.$snotify.error('サーバーエラー');
                    }
                }
                cb([])
                console.log(error)
            })
        },
        newsDismissed(type, aid) {
            switch (type) {
                case 'system':
                    this.$ls.set('news-s', aid)
                    break;
                case 'group':
                    this.$ls.set('news-g', aid)
                    break;
            }
        },
        changePage() {
            window.scrollTo(0, 0)
        },
        getUpload(vid) {
            return this.$getComponent('App').uploadGroups.find((v) => v.vid === vid)
        }
    },
    computed: {
        canRetry() {
            if (this.selectedItem && this.selectedItem.status === 'up_err') {
                if (this.$getComponent('App').uploadGroups.findIndex((v) => v.vid === this.selectedItem.video_id) > -1) {
                    return true;
                }
            }
            return false
        },
        teams() {
            let l = this.groups.filter(item => item.contract).map(item => {
                return {text: item.name, value: item.group_id}
            });
            l.unshift({
                text: '自分のチーム', value: this.user.group.group_id
            });
            l.unshift({
                text: 'すべて', value: null
            })
            return l;
        }
    },
    mounted() {
        this.$root.$on('updateNews', (news) => {
            this.news = news
        });
        this.$root.$on('updateTable', () => {
            if (this.$refs.table) {
                this.$refs.table.refresh()
            }
        });
    }
}
</script>

<style scoped>
.no-round .btn {
    border-radius: 0;
}

#info-card-multi, #info-card {
    min-height: 340px;
}

#toolbox {
    position: sticky;
    top: 70px;
    z-index: 999;
    background-color: white;
}

#info-box {
    position: sticky;
    top: 140px;
}

</style>

<style>
th.status {
    width: 120px;
}

th.group {
    width: 150px;
}

th.date {
    width: 150px;
}

.thumb-sm {
    width: 40px;
}
</style>
