<template>
    <div>
        <Staff v-if="this.loggedIn && user.is_staff" :groups="teams"></Staff>
        <Main v-if="this.loggedIn && !mobile && !user.is_staff" :groups="teams" :user="user"></Main>
        <MainMobile v-if="this.loggedIn && mobile && !user.is_staff" :groups="teams" :user="user"></MainMobile>
        <UploadModal v-if="this.loggedIn && user.is_manager && !mobile" :teams="teams" :team_id="user.group.group_id"/>
        <UploadProgressModal v-if="this.loggedIn && user.is_manager && !mobile"/>
        <VideoEditModal v-if="this.loggedIn && user.is_manager" :teams="teams" :team_id="user.group.group_id"/>
        <VideoLabelModal v-if="this.loggedIn && user.is_manager" :teams="teams" :team_id="user.group.group_id"/>
        <AclModal v-if="this.loggedIn && user.is_manager && !$env().VUE_APP_NOSHARE" :teams="teams" :team_id="user.group.group_id"/>
        <VideoModal v-if="this.loggedIn"/>
        <VideoSearchModal v-if="this.loggedIn && !mobile"/>
        <VideoSearchMobileModal v-if="this.loggedIn && mobile"/>
        <Login v-if="!this.loggedIn"/>
    </div>
</template>

<script>
import UploadModal from '@/components/UploadModal'
import Login from '@/components/Login'
import VideoModal from '@/components/VideoModal'
import AclModal from '@/components/AclModal'
import axios from 'axios'
import Main from '@/components/Main'
import MainMobile from '@/components/MainMobile'
import UploadProgressModal from '@/components/UploadProgressModal'
import VideoEditModal from '@/components/VideoEditModal'
import {ja} from 'vuejs-datepicker/dist/locale'
import {settings} from '@/variables'
import Staff from '@/components/Staff'
import Worker from 'worker-loader!@/worker'
import VideoLabelModal from "@/components/VideoLabelModal";
import VideoSearchModal from "@/components/VideoSearchModal";
import VideoSearchMobileModal from "@/components/VideoSearchMobileModal.vue";
import mixpanel from 'mixpanel-browser'
// import Evaporate from 'evaporate'
// import {sha256} from 'js-sha256'
// import SparkMD5 from 'spark-md5'

export default {
    components: {
        VideoSearchMobileModal,
        VideoSearchModal,
        VideoLabelModal,
        Staff,
        VideoEditModal,
        UploadProgressModal,
        Main,
        VideoModal,
        Login,
        UploadModal,
        MainMobile,
        AclModal
    },
    name: 'Home',
    data() {
        return {
            evaporate: null,
            lastUpdate: Date.now(),
            datePicker: {
                language: ja,
                format: 'yyyy-MM-dd',
                disabledDates: {
                    from: this.$moment().local().add(1, 'd').toDate()
                },
                icon: 'icon ion-md-calendar'
            },
            status: {
                init: 'アップロード待ち',
                up: 'アップロード中',
                up_end: '準備中',
                up_err: 'アップロードエラー',
                enc: '準備中',
                enc_err: '準備エラー',
                ready: '利用可能'
            },
            statusVariant: {
                init: 'light',
                up: 'primary',
                up_end: 'info',
                up_err: 'danger',
                enc: 'dark',
                enc_err: 'danger',
                ready: 'success'
            },
            teams: [],
            worker: null
        }
    },
    computed: {
        user: {
            get() {
                return this.$ls.get('user')
            },
            set(val) {
                console.log(val)
            }
        },
        loggedIn: {
            get() {
                return this.$loggedIn() === true
            },
            set(value) {
                console.log(value)
            }
        },
        mobile() {
            return this.$md.mobile()
        },
        phone() {
            return this.$md.phone()
        },
        deviceOs() {
            return this.$md.os()
        }
    },
    methods: {
        getCookie(name) {
            let cookieValue = null;
            if (document.cookie && document.cookie !== '') {
                const cookies = document.cookie.split(';');
                for (let i = 0; i < cookies.length; i++) {
                    const cookie = cookies[i].trim();
                    // Does this cookie string begin with the name we want?
                    if (cookie.substring(0, name.length + 1) === (name + '=')) {
                        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                        break;
                    }
                }
            }
            return cookieValue;
        },
        workerMethod(event) {
            if (typeof event.data === 'object') {
                let vid = event.data.vid;
                let idx = event.data.idx;
                if ('hash' in event.data) {
                    let hash = event.data.hash;
                    this.$parent.noSleep.disable();
                    this.$http.post('video/' + vid + '?oper=hash', {hash: hash}).then(response => {
                        if (response && response.data.exists) {
                            let group = this.$parent.uploadGroups[idx];
                            for (let i = 0; i < group.uploads.length; i++) {
                                group.uploads[i].uploaded = true;
                                group.uploads[i].progress = 100;
                            }
                            group.hash = hash;
                            group.uploaded = true;
                            group.progress = 100;
                            group.finished = group.uploads.length
                            this.$root.$emit('updateTable');
                            this.uploadNext();
                        } else {
                            this.$parent.uploadGroups[idx].hash = hash;
                            if (this.$parent.uploadGroups.filter((v) => v.uploading).length < 2) {
                                this.startUploadGroup(idx);
                            }
                        }
                    }).catch((error) => {
                        if (error.response) {
                            if (error.response.data && error.response.data.error) {
                                this.$snotify.error(error.response.data.error);
                            } else {
                                this.$snotify.error('サーバーエラー');
                            }
                        }
                    })
                } else if ('percent' in event.data) {
                    window.requestAnimationFrame(()=> {
                        this.$parent.uploadGroups[idx].process = Math.ceil(event.data.percent * 100);
                    })
                } else {
                    this.$parent.noSleep.disable();
                    // even when hashing error, I think we should upload the file anyway
                    this.$parent.uploadGroups[idx].hash = 'error';
                    if (this.$parent.uploadGroups.filter((v) => v.uploading).length < 2) {
                        this.startUploadGroup(idx);
                    }
                    if (event.data instanceof Error) {
                        this.$snotify.error(event.data.message, 'アップロード準備エラー');
                        // eslint-disable-next-line no-undef
                        this.$log( 'hashError', {'event_label': 'vid: ' + vid + ' -- ' + event.data.message});
                    }
                    console.log(typeof event.data, event.data)
                }
            }
        },
        /**
         * Update news
         */
        updateNews() {
            this.$http.get('news/latest', {
                params: {
                    group: this.$ls.get('news-g', 0),
                    system: this.$ls.get('news-s', 0)
                }
            }).then((response)  => {
                if (response) {
                    this.$root.$emit('updateNews', response.data.news);
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.data && error.response.data.error) {
                        this.$snotify.error(error.response.data.error);
                    } else {
                        this.$snotify.error('サーバーエラー');
                    }
                }
            })
        },
        /**
         * Get video Url for play
         * @param video Video object from server
         * @param cb Callback
         */
        getPlayURL(video, cb) {
            if (video.status !== 'ready' || video.archived) {
                return;
            }

            this.$getComponent('App').loading(true)
            this.$http.get('url/play/' + video.video_id).then((response) => {
                this.$root.$emit('endSpinner');
                if (response) {
                    if (typeof cb === 'function') {
                        cb(response.data.url)
                    }
                }
            }).catch((error) => {
                this.$root.$emit('endSpinner');
                if (error.response) {
                    if (error.response.data && error.response.data.error) {
                        this.$snotify.error(error.response.data.error);
                    } else {
                        this.$snotify.error('サーバーエラー');
                    }
                }
            })
        },
        /**
         * Create one time url
         **/
        createOneTimeURL(selectedItem) {
            if (selectedItem) {
                let row = this.phone ? 2 : 1;
                this.$getComponent('App').loading(true)
                this.$http.get('url/query/' + selectedItem.video_id).then(response => {
                    this.$root.$emit('endSpinner');
                    this.$swal({
                        title: 'ダウンロード専用URL',
                        html: '<div>残り作成上限：<span>' + response.data.count + '</span> / <span>' + response.data.limit + '</span><br>新しいDL専用URLを作成しますか？</div>',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#ed9d2b',
                        cancelButtonText: 'キャンセル',
                        confirmButtonText: '続行'
                    }).then((result) => {
                        if (result.value) {
                            this.$getComponent('App').loading(true)
                            this.$http.get('url/onetime/' + selectedItem.video_id).then((response) => {
                                this.$root.$emit('endSpinner');
                                let url = location.protocol + '//' + location.host + '/#/download/' + response.data.hash;
                                this.$swal({
                                    title: 'ダウンロード専用URL',
                                    type: 'info',
                                    html: '<div class="form-group"><textarea id="dlurl" class="form-control text-center" rows="' + row +
                                        '" style="display:block; height:auto; resize: none;" readonly>' + url + '</textarea></div>' +
                                        '<div class="small">作成上限：<span>' + response.data.count + '</span> / <span>' + response.data.limit + '</span><br>' +
                                        response.data.expire + 'までに有効</div>'
                                });
                                document.querySelector('#dlurl').addEventListener('click', evt => {
                                    let copy = false;
                                    let target = evt.target;
                                    if (this.deviceOs === 'iOS' || this.deviceOs === 'AndroidOS') {
                                        target.readonly = false;
                                        target.contenteditable = true;
                                        let range = document.createRange();
                                        range.selectNodeContents(target);
                                        let s = window.getSelection();
                                        s.removeAllRanges();
                                        s.addRange(range);
                                        target.select();
                                        target.setSelectionRange(0, 999999);
                                        copy = document.execCommand('copy');
                                        if (this.deviceOs === 'iOS') {
                                            target.readonly = true;
                                            target.contenteditable = false;
                                        }
                                    } else {
                                        target.select();
                                        copy = document.execCommand('copy');
                                    }
                                    if (copy) {
                                        this.$swal.showValidationMessage('ダウンロード専用URLをクリップボードにコピーしました')
                                        setTimeout(() => {
                                            this.$swal.resetValidationMessage();
                                        }, 3000);
                                    }
                                })
                                /* eslint-disable no-undef */
                                this.$log( 'share', {'method': selectedItem.name});
                            }).catch((error) => {
                                this.$root.$emit('endSpinner');
                                if (error.response) {
                                    switch (error.response.status) {
                                        case 404:
                                            this.$snotify.error('指定した映像が存在しない');
                                            break;
                                        case 403:
                                            this.$snotify.error('作成する数の上限が越えました');
                                            break;
                                        default:
                                            if (error.response.data && error.response.data.error) {
                                                this.$snotify.error(error.response.data.error);
                                            } else {
                                                this.$snotify.error('サーバーエラー');
                                            }
                                            break;
                                    }
                                }
                            })
                        }
                    })
                }).catch(error => {
                    this.$root.$emit('endSpinner');
                    if (error.response) {
                        switch (error.response.status) {
                            case 404:
                                this.$snotify.error('指定した映像が存在しない');
                                break;
                            case 403:
                                this.$snotify.error('作成する数の上限が越えました');
                                break;
                            default:
                                if (error.response.data && error.response.data.error) {
                                    this.$snotify.error(error.response.data.error);
                                } else {
                                    this.$snotify.error('サーバーエラー');
                                }
                                break;
                        }
                    }
                })
            }
        },
        /**
         * Archive selected video
         */
        archiveVideo(selectedItem, force) {
            if (selectedItem) {
                if (!force && selectedItem.status === 'ready') {
                    let acl = selectedItem.acl.filter(value => value.streaming).map(value => value.group_id_id).filter(value => value !== selectedItem.group_id);
                    if (acl.length) {
                        this.$swal({
                            title: '映像公開中',
                            text: `この映像が他チームに公開している。そのまま続行しますか？`,
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#ed9d2b',
                            cancelButtonText: 'キャンセル',
                            confirmButtonText: '続行'
                        }).then((result) => {
                            if (result.value) {
                                this.archiveVideo(selectedItem, true);
                            }
                        })
                        return;
                    }
                }
                this.$swal({
                    title: '映像アーカイブ',
                    html: `「${selectedItem.name}」をアーカイブしますか？<br>以下の空欄に「archive」を入力して、アーカイブボタンをクリックしてください`,
                    input: 'text',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#d9534f',
                    cancelButtonText: 'キャンセル',
                    confirmButtonText: 'アーカイブ',
                    inputValidator: (value) => {
                        if (!value || value !== 'archive') {
                            return '空欄に「archive」を入力してください'
                        }
                    }
                }).then((result) => {
                    if (result.value) {
                        this.$root.$emit('closeInfoModal');
                        this.$getComponent('App').loading(true)
                        this.$http.post('video/' + selectedItem.video_id + '?oper=archive').then((response) => {
                            this.$root.$emit('endSpinner');
                            if (response) {
                                this.$root.$emit('updateTable');
                                this.$snotify.success('「' + selectedItem.name + '」がアーカイブされました。');
                                this.$log( 'archive', {'vid': selectedItem.video_id});
                            }
                        }).catch((error) => {
                            this.$root.$emit('endSpinner');
                            if (error.response) {
                                switch (error.response.status) {
                                    case 404:
                                        this.$snotify.error('指定した映像が存在しない');
                                        break;
                                    case 403:
                                        this.$snotify.error('アーカイブする権限がありません');
                                        break;
                                    default:
                                        if (error.response.data && error.response.data.error) {
                                            this.$snotify.error(error.response.data.error);
                                        } else {
                                            this.$snotify.error('サーバーエラー');
                                        }
                                        break;
                                }
                            }
                        })
                    }
                })
            }
        },
        /**
         * Archive selected video (for manager)
         */
        restoreVideo(selectedItem) {

            this.$http.get('group/restore').then(response => {
                if (response) {
                    if (response.data.active >= response.data.max_active + 5) {
                        this.$snotify.error('アクティブ映像が上限（' + response.data.max_active + '）を越えました、古い映像をアーカイブしてください')
                        return;
                    }
                    if (response.data.max - response.data.count < 1) {
                        this.$snotify.error('復元可能数が越えました');
                        return;
                    }
                    this.$swal({
                        title: '映像復元',
                        html: `「${selectedItem.name}」を復元しますか？（復元数：${response.data.count}/${response.data.max}）<br>以下の空欄に「restore」を入力して、復元ボタンをクリックしてください。`,
                        input: 'text',
                        type: 'warning',
                        showCancelButton: true,
                        cancelButtonText: 'キャンセル',
                        confirmButtonText: '復元',
                        inputValidator: (value) => {
                            if (!value || value !== 'restore') {
                                return '空欄に「restore」を入力してください'
                            }
                        }
                    }).then((result) => {
                        if (result.value) {
                            this.restore(selectedItem);
                        }
                    })
                }
            }).catch(error => {
                if (error.response) {
                    if (error.response.data && error.response.data.error) {
                        this.$snotify.error(error.response.data.error);
                    } else {
                        this.$snotify.error('サーバーエラー');
                    }
                }
            })
        },
        /**
         * Restore selected video
         */
        restore(selectedItem) {
            if (selectedItem) {
                this.$getComponent('App').loading(true)
                this.$http.post('video/' + selectedItem.video_id + '?oper=restore').then((response) => {
                    this.$root.$emit('endSpinner');
                    if (response) {
                        this.$root.$emit('closeInfoModal');
                        this.$root.$emit('updateTable');
                        this.$log( 'restore', {'vid': selectedItem.video_id});
                    }
                }).catch((error) => {
                    this.$root.$emit('endSpinner');
                    if (error.response) {
                        switch (error.response.status) {
                            case 404:
                                this.$snotify.error('指定した映像が存在しない');
                                break;
                            case 403:
                                this.$snotify.error('復元する権限がありません');
                                break;
                            default:
                                if (error.response.data && error.response.data.error) {
                                    this.$snotify.error(error.response.data.error);
                                } else {
                                    this.$snotify.error('サーバーエラー');
                                }
                                break;
                        }
                    }
                })
            }
        },
        /**
         * Delete selected video
         */
        deleteVideo(selectedItem, force) {
            if (selectedItem) {
                if (!force && selectedItem.status === 'ready') {
                    let acl = selectedItem.acl.filter(value => value.streaming).map(value => value.group_id_id).filter(value => value !== selectedItem.group_id);
                    if (acl.length) {
                        this.$swal({
                            title: '映像公開中',
                            text: `この映像が他チームに公開している。そのまま続行しますか？`,
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#ed9d2b',
                            cancelButtonText: 'キャンセル',
                            confirmButtonText: '続行'
                        }).then((result) => {
                            if (result.value) {
                                this.deleteVideo(selectedItem, true);
                            }
                        })
                        return;
                    }
                }
                this.$swal({
                    title: '映像削除',
                    html: `「${selectedItem.name}」を削除しますか？<br>以下の空欄に「delete」を入力して、削除ボタンをクリックしてください`,
                    input: 'text',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#d9534f',
                    cancelButtonText: 'キャンセル',
                    confirmButtonText: '削除',
                    inputValidator: (value) => {
                        if (!value || value !== 'delete') {
                            return '空欄に「delete」を入力してください'
                        }
                    }
                }).then((result) => {
                    if (result.value) {
                        let wait = 0;
                        let uIdx = this.$parent.uploadGroups.findIndex((v) => v.vid === selectedItem.video_id)
                        if (uIdx > -1) {
                            this.cancelUpload(uIdx, '削除されました');
                            wait = 1000;
                        }
                        this.$root.$emit('closeInfoModal');
                        setTimeout(() => {
                            this.$http.delete('video/' + selectedItem.video_id).then(() => {
                                this.$root.$emit('updateTable');
                                this.$snotify.success('「' + selectedItem.name + '」が削除されました。'); // TODO
                                setTimeout(() => {
                                    this.$parent.uploadGroups.splice(uIdx, 1);
                                }, wait)
                                this.$log( 'delete', {'vid': selectedItem.video_id});
                            }).catch((error) => {
                                if (error.response) {
                                    switch (error.response.status) {
                                        case 404:
                                            this.$snotify.error('指定した映像が存在しない');
                                            break;
                                        case 403:
                                            this.$snotify.error('削除する権限がありません');
                                            break;
                                        default:
                                            if (error.response.data && error.response.data.error) {
                                                this.$snotify.error(error.response.data.error);
                                            } else {
                                                this.$snotify.error('サーバーエラー');
                                            }
                                            break;
                                    }
                                }
                            })
                        }, wait);
                    }
                })
            }
        },
        /**
         * Update upload status
         * @param idx
         * @param uploadId
         * @param status
         */
        updateUpload(idx, uploadId, status) {

            let group = this.$parent.uploadGroups[idx];
            if (!group) {
                return;
            }
            let uIdx = group.uploads.findIndex((v) => v.uploadId === uploadId)
            this.$http.post('upload/' + uploadId, {
                status: status
            }).then((response) => {
                if (response) {
                    switch (status) {
                        case 'up_end':
                            if (response.data.video) {
                                group.progress = 100;
                                group.uploaded = true;
                                group.uploading = false;
                                group.error = false;
                                this.$root.$emit('updateTable');
                                this.$snotify.success('「' + response.data.video.name + '」のアップロードが成功しました。サーバーでの処理しています、しばらくお待ちください。'); // TODO
                                this.$parent.uploading = this.$parent.uploadGroups.filter((v) => v.uploading).length > 0;
                                this.uploadNext();
                            } else {
                                this.startUploadGroup(idx);
                            }
                            break;
                        case 'up':
                            if (uIdx > -1 && group.uploads[uIdx].chunk === 1) {
                                this.$root.$emit('updateTable');
                            }
                            break;
                        case 'up_err':
                            this.$root.$emit('updateTable');
                            break;
                        default:
                            // TODO: tokuni nashi
                            break;
                    }
                }
            }).catch((error) => {
                if (uIdx > -1) {
                    group.uploads[uIdx].error = true;
                    group.uploads[uIdx].uploading = false;
                    group.uploads[uIdx].msg = error.message;
                }
                group.uploading = false;
                group.error = true;
                group.msg = error.message;

                this.uploadNext();
                this.$root.$emit('updateTable');

                if (error.response) {
                    if (error.response.data && error.response.data.error) {
                        this.$snotify.error(error.response.data.error);
                    } else {
                        this.$snotify.error('サーバーエラー');
                    }
                }
            })
        },
        /**
         * Init upload process
         */
        startUploadGroup(idx) {
            console.log('Start', idx);

            if (idx >= this.$parent.uploadGroups.length) {
                return;
            }
            let group = this.$parent.uploadGroups[idx];
            if (!group.hash) {
                return;
            }

            let vid = group.vid;
            for (let i = 0; i < group.uploads.length; i++) {
                let u = group.uploads[i];
                if (u.uploaded) {
                    continue;
                }
                if (u.uploading) {
                    break;
                }
                let uploadId = u.uploadId;

                this.$http.post('upload/' + uploadId, {
                    status: 'init',
                    filename: u.name,
                    size: u.file.size,
                    chunk: u.chunk,
                    chunks: u.chunks,
                    vid: vid
                }).then((response) => {
                    console.log(response)
                    let url = response.data.post.url;
                    let fields = response.data.post.fields;
                    let totalSize = group.uploads.reduce((previousValue, currentValue) => previousValue + currentValue.file.size, 0)
                    let uploadedSize = group.uploads.filter((v) => v.uploaded).reduce((previousValue, currentValue) => previousValue + currentValue.file.size, 0)
                    console.log('totalSize', totalSize)
                    console.log('uploadedSize', uploadedSize)
                    uploadId = uploadId || response.data.upload.upload_id;

                    group.uploads[i].uploadId = uploadId;
                    group.uploads[i].error = false;
                    group.uploads[i].msg = '';
                    group.uploads[i].path = response.data.upload.path;

                    group.error = false;
                    group.msg = '';
                    group.uploading = true;
                    group.uploads[i].uploading = true;
                    let cancelToken = axios.CancelToken;
                    group.source = cancelToken.source();
                    const csrftoken = this.getCookie('csrftoken');
                    let instance = axios.create({
                        baseURL: response.data.post.conoha ? this.$baseUrl : null,
                        cancelToken: group.source.token,
                        responseType: response.data.post.conoha ? 'application/json' : 'document',
                        headers: response.data.post.conoha ? {
                            'X-Requested-With': 'XMLHttpRequest',
                            'Authorization': 'Token ' + this.$ls.get('token'),
                            'X-CSRFToken': csrftoken ? csrftoken : null,
                            'X-Auth-Token': response.data.post.token ? response.data.post.token : null
                        }: null,
                        onUploadProgress: (progressEvent) => {
                            this.$parent.uploading = true;
                            let v = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            if (v % 2 === 0) {
                                group.uploads[i].progress = v
                                group.progress = group.total === 1 ? v : Math.round(((uploadedSize + progressEvent.loaded) * 100) / totalSize);
                            }
                        }
                    });

                    this.updateUpload(idx, uploadId, 'up');

                    if (response.data.post.url2) {
                        instance.put(response.data.post.url2, u.file).then(() => {
                            group.uploads[i].uploading = false;
                            group.uploads[i].uploaded = true;
                            group.uploads[i].progress = 100;
                            let finished = group.uploads.filter((v) => v.uploaded);
                            group.finished = finished.length;
                            if (group.total > 1) {
                                uploadedSize = finished.reduce((previousValue, currentValue) => previousValue + currentValue.file.size, 0)
                                group.progress = Math.round(((uploadedSize) * 100) / totalSize);
                            }
                            group.source = null;
                            this.updateUpload(idx, uploadId, 'up_end');
                            /* eslint-disable no-undef */
                            this.$log( 'uploadEnd', {'event_label': uploadId});
                        }).catch((error) => {
                            group.uploads[i].uploading = false;
                            group.uploads[i].error = true;
                            group.uploads[i].msg = error.message;
                            group.uploading = false;
                            group.error = true;
                            group.msg = error.message;
                            this.$parent.uploading = this.$parent.uploadGroups.filter((v) => v.uploading).length > 0;
                            this.updateUpload(idx, uploadId, 'up_err');
                            if (error.response) {
                                if (error.response.data && error.response.data.error) {
                                    this.$snotify.error(error.response.data.error);
                                } else {
                                    this.$snotify.error('サーバーエラー');
                                }
                            }
                            console.log(error)
                            /* eslint-disable no-undef */
                            this.$log( 'uploadError', {'event_label': 'upid: ' + uploadId + ' -- ' + error.message});
                        })
                    } else {
                        let formData = new FormData();
                        Object.keys(fields).forEach((key) => {
                            formData.append(key, fields[key]);
                        })
                        formData.append('file', u.file);
                        instance.post(url, formData).then(() => {
                            group.uploads[i].uploading = false;
                            group.uploads[i].uploaded = true;
                            group.uploads[i].progress = 100;
                            let finished = group.uploads.filter((v) => v.uploaded);
                            group.finished = finished.length;
                            if (group.total > 1) {
                                uploadedSize = finished.reduce((previousValue, currentValue) => previousValue + currentValue.file.size, 0)
                                group.progress = Math.round(((uploadedSize) * 100) / totalSize);
                            }
                            group.source = null;
                            this.updateUpload(idx, uploadId, 'up_end');
                            /* eslint-disable no-undef */
                            this.$log( 'uploadEnd', {'event_label': uploadId});
                        }).catch((error) => {
                            group.uploads[i].uploading = false;
                            group.uploads[i].error = true;
                            group.uploads[i].msg = error.message;
                            group.uploading = false;
                            group.error = true;
                            group.msg = error.message;
                            this.$parent.uploading = this.$parent.uploadGroups.filter((v) => v.uploading).length > 0;
                            this.updateUpload(idx, uploadId, 'up_err');
                            if (error.response) {
                                if (error.response.data && error.response.data.error) {
                                    this.$snotify.error(error.response.data.error);
                                } else {
                                    this.$snotify.error('サーバーエラー');
                                }
                            }
                            console.log(error)
                            /* eslint-disable no-undef */
                            this.$log( 'uploadError', {'event_label': 'upid: ' + uploadId + ' -- ' + error.message});
                        })
                    }
                }).catch((error) => {
                    group.uploads[i].uploading = false;
                    group.uploads[i].error = true;
                    group.uploads[i].msg = error.message;
                    group.uploading = false;
                    group.error = true;
                    group.msg = error.message;
                    this.$parent.uploading = this.$parent.uploadGroups.filter((v) => v.uploading).length > 0;
                    if (uploadId) {
                        this.updateUpload(idx, uploadId, 'up_err');
                    }
                    if (error.response) {
                        if (error.response.data && error.response.data.error) {
                            this.$snotify.error(error.response.data.error);
                        } else {
                            this.$snotify.error('サーバーエラー');
                        }
                    }
                    console.log(error)
                    /* eslint-disable no-undef */
                    this.$log( 'uploadError', {'event_label': 'vid: ' + vid + ' -- ' + error.message});
                });
                break
            }
        },
        /**
         * Upload next group
         */
        uploadNext() {
            if (this.$parent.uploadGroups.filter((v) => v.uploading).length < 2) {
                let initIdx = this.$parent.uploadGroups.findIndex((v) => v.hash && !v.uploading && !v.uploaded && !v.error)
                console.log('next idx: ' + initIdx)
                if (initIdx > -1) {
                    this.startUploadGroup(initIdx)
                }
            }
        },
        /**
         * Cancel upload
         */
        cancelUpload(idx, msg) {
            if (this.$parent.uploadGroups.length > idx) {
                if (this.$parent.uploadGroups[idx].source) {
                    this.$parent.uploadGroups[idx].source.cancel(msg)
                }
                this.$parent.uploadGroups[idx].uploading = false;
                this.$parent.uploadGroups[idx].uploaded = false;
                this.$parent.uploadGroups[idx].error = true;
                this.$parent.uploadGroups[idx].msg = msg;
                this.$parent.uploading = this.$parent.uploadGroups.filter((v) => v.uploading).length > 0;
                this.uploadNext();
            }
        },
        startUpload(name, vid, uploads) {

            let l = this.$parent.uploadGroups.push({
                name: name,
                vid: vid,
                total: uploads.length,
                finished: 0,
                progress: 0,
                uploaded: false,
                uploading: false,
                error: false,
                msg: '',
                hash: null,
                process: 0,
                uploads: uploads
            })
            // let ext = uploads[0].name.toLowerCase().split('.').pop();
            let idx = l - 1;
            // changed 2022-03-11 hash all file
            this.$parent.noSleep.enable();
            this.worker.postMessage({
                idx,
                vid,
                files: uploads.map(v => v.file)
            })
        },
        populateTeams() {
            this.$http.get('group/').then((r) => {
                if (r) {
                    this.teams = r.data.groups
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.data && error.response.data.error) {
                        this.$snotify.error(error.response.data.error);
                    } else {
                        this.$snotify.error('サーバーエラー');
                    }
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log(error.config);
                }
                if (this.$ab) {
                    this.$ab.notify(error);
                }
            })
        }
    },
    created() {
        this.worker = new Worker();
        this.worker.addEventListener('message', this.workerMethod);
        let sess = () => {
            this.$http.post('session', {
                guid: this.$guid()
            }).then((response) => {
                if (response) {
                    if (this.$ls.get('password', null) && response.data.token) {
                        this.$ls.set('token', response.data.token, 86400000);
                    }
                    /* eslint-disable no-undef */
                    gtag('set', {'user_id': response.data.user.username}); // Set the user ID using signed-in user_id.
                    mixpanel.identify(response.data.user.username)
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        this.loggedIn = false;
                        this.$ls.remove('token')
                        this.$ls.remove('user')
                        if (this.loggedIn) {
                            this.loggedIn = false;
                            if (typeof Android !== 'undefined') {
                                Android.reload();
                            } else {
                                window.location.reload();
                            }
                        }
                    } else {
                        if (this.$ab) {
                            this.$ab.notify(error);
                        }
                        if (error.response.data && error.response.data.error) {
                            this.$snotify.error(error.response.data.error);
                        } else {
                            this.$snotify.error('サーバーエラー');
                        }
                    }
                } else {
                    if (this.$ab) {
                        this.$ab.notify(error);
                    }
                }
            })
        }
        if (this.$guid()) {
            if (window.requestIdleCallback) {
                window.requestIdleCallback(sess, {timeout: 600})
            } else {
                setTimeout(sess, 600)
            }
        }
    },
    destroyed() {
        if (this.worker) {
            this.worker.removeEventListener('message', this.workerMethod);
            this.worker.terminate();
        }
    },
    mounted() {
        gtag('config', settings.gtag, {'page_path': '/home'});
        this.$root.$on('loadNews', () => {
            this.updateNews();
        });
        this.$root.$on('startUpload', (idx) => {
            this.startUploadGroup(idx);
        });
        this.$root.$on('stopUpload', (idx) => {
            this.cancelUpload(idx, ' ユーザによってキャンセルされた');
        });
        window.addEventListener('focus', () => {
            if (Date.now() - this.lastUpdate > settings.nextFocusUpdate) {
                this.$http.get('session').then(response => {
                    if (response) {
                        this.$root.$emit('updateTable');
                        this.updateNews();
                        this.lastUpdate = Date.now();
                    }
                }).catch(error => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            this.$ls.remove('token')
                            this.$ls.remove('user')
                            if (this.loggedIn) {
                                this.loggedIn = false;
                                location.reload();
                            }
                        } else {
                            if (error.response.data && error.response.data.error) {
                                this.$snotify.error(error.response.data.error);
                            } else {
                                this.$snotify.error('サーバーエラー');
                            }
                        }
                    }
                })
            }
        });
        if (this.loggedIn) {
            this.updateNews();
            this.populateTeams();
        }
    }
}
</script>

<style>
.playbutton {
    position: absolute;
    width: 64px;
    height: 64px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.thumbLarge {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    padding-bottom: 62.5%;
}

img.thumb {
    width: 80px;
    height: 60px;
}
</style>
