<template>
    <b-modal id="modalAcl" title="閲覧権限を編集" size="md" ref="aclModal" cancel-title="キャンセル" ok-title="保存" @ok="submit"
             @shown="modalOpen">
        <b-form @submit.prevent ref="videoEditForm">
            <div class="row">
                <div class="col">
                    <b-form-group :label="aclSelected">
                        <div class="px-1" style="max-height: 330px; overflow-y: auto;">
                            <b-form-checkbox v-for="team in teams"
                                             v-bind:key="team.group_id"
                                             v-model="acls[team.group_id]"
                                             :indeterminate="acls[team.group_id]>0&&acls[team.group_id]!==vids.length"
                                             :name="'teams['+team.group_id+']'"
                                             :unchecked-value="0"
                                             @change="changed"
                                             :value="vids.length">
                                {{ team.name }}
                            </b-form-checkbox>
                        </div>
                    </b-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <b-btn size="sm" block @click="checkAll" variant="outline-success">すべてをチェック</b-btn>
                </div>
                <div class="col">
                    <b-btn size="sm" block @click="unCheckAll" variant="outline-danger">すべてを外す</b-btn>
                </div>
            </div>
        </b-form>
    </b-modal>
</template>

<script>
// import Datepicker from 'vuejs-datepicker';

export default {
    name: 'AclModal',
    // components: {
    //   Datepicker
    // },
    props: {
        teams: Array,
        team_id: Number
    },
    data() {
        return {
            vids: [],
            acls: {},
            aclSelected: ''
        }
    },
    methods: {
        changed() {
            let l = Object.keys(this.acls).map(x => this.acls[x]).filter(x => x > 0).length - 1
            console.log(l)
            this.aclSelected = l > 0 ? '（' + l + '件選択中）' : ''
        },
        unCheckAll() {
            for (let k in this.acls) {
                this.$set(this.acls, k, 0)
            }
            this.$set(this.acls, this.team_id, this.vids.length)
            this.changed()
            this.$forceUpdate()
        },
        checkAll() {
            for (let k in this.acls) {
                this.$set(this.acls, k, this.vids.length)
            }
            this.$set(this.acls, this.team_id, this.vids.length)
            this.changed()
            this.$forceUpdate()
        },
        modalOpen() {
            //
        },
        submit(e) {
            e.preventDefault();
            let data = {
                oper: 'acl',
                vids: this.vids,
                acl: this.acls
            };
            this.$getComponent('App').loading(true)
            this.$http.post('video/', data).then((response) => {
                this.$root.$emit('endSpinner');
                if (response) {
                    this.$refs.aclModal.hide();
                    this.$root.$emit('updateTable');
                    /* eslint-disable no-undef */
                    this.$log( 'edit', {'event_label': this.vids.join(',')});
                }
            }).catch((error) => {
                this.$root.$emit('endSpinner');
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            this.$loggedIn = false;
                            break;
                        case 403:
                            this.$snotify.error('削除する権限がありません');
                            break;
                        default:
                            if (error.response.data && error.response.data.error) {
                                this.$snotify.error(error.response.data.error);
                            } else {
                                this.$snotify.error('サーバーエラー');
                            }
                            break;
                    }
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    this.$snotify.error(error.message)
                    console.log(error.config);
                }
            })
        },
        openAclModal(videos) {
            this.teams.forEach((g) => {
                this.acls[g.group_id] = 0;
            })
            this.acls[this.team_id] = videos.length;
            console.log('modal open')
            this.unCheckAll();
            this.vids = videos.map(a => a.video_id);
            for (let i = 0; i < videos.length; i++) {
                videos[i].acl.forEach((v) => {
                    if (v.streaming) {
                        if (typeof this.acls[v.group_id_id] !== 'undefined') {
                            this.acls[v.group_id_id]++;
                        }
                    }
                })
            }
            this.changed()
            this.$forceUpdate()
            this.$refs.aclModal.show();
        }
    },
    created() {
        this.$root.$on('openAclModal', this.openAclModal);
    },
    destroyed() {
        this.$root.$off('openAclModal', this.openAclModal);
    }
}
</script>
