<template>
    <div id="app">
        <Header/>
        <router-view></router-view>
        <Footer/>
    </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import NoSleep from 'nosleep.js'
import {sleep} from './sleep'

export default {
    name: 'App',
    components: {Footer, Header},
    data() {
        return {
            uploadGroups: [],
            uploads: [],
            uploading: false,
            noSleep: new NoSleep()
        }
    },
    methods: {
        loading(show) {
            if (show) {
                document.querySelector('#loading').classList.remove('d-none');
            } else {
                document.querySelector('#loading').classList.add('d-none');
            }
        }
    },
    watch: {
        uploading: function (val) {
            if (val) {
                console.log('Try to prevent sleep')
                this.noSleep.enable();
                sleep.prevent()
            } else {
                console.log('Allow sleep')
                this.noSleep.disable();
                sleep.allow()
            }
        }
    },
    mounted() {
        window.addEventListener('beforeunload', (e) => {
            // Cancel the event
            if (this.uploading || this.uploadGroups.length) {
                e.preventDefault();
                // Chrome requires returnValue to be set
                e.returnValue = true;
                return '';
            }
        });
        this.$root.$on('endSpinner', () => {
            this.loading(false)
        });
        this.$root.$on('loading', (b) => {
            this.loading(b)
        });
    }
}
</script>

<style>
html {
    position: relative;
    min-height: 100%;
}

body {
    font-family: 'Noto Sans JP', sans-serif !important;
    /* Margin bottom by footer height */
    margin-bottom: 60px;
    overflow-x: hidden;
    min-height: 100%;
}

main.container-fluid {
    padding: 70px 15px 70px;
}

main.container {
    padding: 70px 15px 70px;
}

.body-no-scroll {
    overflow: hidden;
}

.swal2-toast .swal2-content {
    max-width: 400px;
}

.swal2-toast #swal2-content {
    text-align: left !important;
}

#modalSearchVideo___BV_modal_outer_ {
    z-index: 10000 !important;
}

.swal2-container {
    z-index: 9999 !important;
}
</style>
